import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./page/auth/login";
import Home from "./page/home/home";
import MainLayout from "./components/Layout/Layout";
import LoginLayout from "./components/Layout/LayoutLogin";
import PostEditor from "./page/CreatePost/PostEditor";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route name="/" element={<MainLayout />}>
          <Route path="" element={<Home />} />
          <Route path="create" element={<PostEditor />} />
          <Route path="home" element={<Home />} />
        </Route>
        <Route path="/" element={<LoginLayout />}>
          <Route path="login" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
