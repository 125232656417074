import React, { useState } from "react";
import "./Login.css";
import { Button } from "antd";
import { request } from "../../system/request";
import { openNotification } from "../../share/message";
const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setUserName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onCLickLogin = async () => {
    const param = new URLSearchParams({
      username: username,
      password: password,
    });

    const res = await request("userlogin?" + param.toString(), "get");

    if (res.isSuccess) {
      localStorage.setItem("isLogin", "1");
      window.location.href = "/"; //
    } else {
      openNotification(
        "error",
        "Error",
        "Please Check Username and Passsword agian!"
      );
    }
  };

  return (
    <div className="login-container">
      <form className="login-form">
        <div>
          <h2>Login</h2>
        </div>
        <div className="form-group">
          <p htmlFor="email">UserName</p>
          <input
            id="username"
            value={username}
            onChange={handleEmailChange}
            required
          />
        </div>
        <div className="form-group">
          <p htmlFor="password">Password</p>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
        </div>

        <div
          style={{
            alignContent: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={onCLickLogin}
          >
            Login
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
