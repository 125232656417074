import { notification } from "antd";

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";

export const openNotification = (type, message, description) => {
  let icon;
  let iconColor;

  switch (type) {
    case "success":
      icon = <CheckCircleOutlined />;
      iconColor = "#52c41a"; // Green color for success
      break;
    case "error":
      icon = <CloseCircleOutlined />;
      iconColor = "#f5222d"; // Red color for error
      break;
    case "remove":
      icon = <CloseOutlined />;
      iconColor = "#f5222d"; // Red color for error
      break;
    // Add more cases as needed for other notification types

    default:
      // Default to info circle for other types
      icon = <InfoCircleOutlined />;
      iconColor = "#1890ff"; // Blue color for info
  }

  notification[type]({
    message,
    description,
    icon: icon,
    style: { color: iconColor, fontFamily: "Khmer OS Battambang, sans-serif" },
    placement: "topRight",
  });
};
