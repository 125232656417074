import {
  Button,
  Input,
  Select,
  Space,
  Table,
  Tag,
  Modal,
  Checkbox,
  Pagination,
  Popconfirm,
} from "antd";
import "./home.css";
import { useEffect, useState } from "react";
import { request } from "../../system/request";
import JoditEditor from "jodit-react";
import { openNotification } from "../../share/message";
import { Circles } from "react-loader-spinner";

const { Option } = Select;
const { TextArea } = Input;

const Home = () => {
  const [id, setID] = useState("");
  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const [visableModel, setModelVisable] = useState(false);
  const [slectCategory, setslectCategory] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const [title, setTitle] = useState("");
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");
  const [ListCategory, setListCategory] = useState([]);
  const [loading, setloading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [TotalPage, setTotalPage] = useState("");
  const [objFilter, setObjFilter] = useState({
    page: 1,
    txtsearch: "",
  });

  useEffect(() => {
    GetListEdit();
    if (searchTerm.length > 0) {
      var objTmp = {
        ...objFilter,
        txtsearch: searchTerm,
        page: searchTerm === "" ? 1 : objFilter.page,
      };
      GetList(objTmp);
      //   setObjFilter(objTmp);
    } else {
      var objTmp = {
        ...objFilter,
        txtsearch: "",
        page: 1,
      };
      GetList(objTmp);
      //    setObjFilter(objTmp);
    }
  }, [searchTerm]);

  const handleCreatePost = async () => {
    if (title == "" || coverImageUrl == "" || content == "" || category == "") {
      openNotification("error", "Error", "Please complete all fields");
    } else {
      const data = {
        post_title: title,
        post_cover: coverImageUrl,
        post_content: content,
        post_category: category,
        status: enable == true ? "1" : "0",
        id: id,
      };

      try {
        setloading(true);
        const method = id === null ? "post" : "put";
        // const res = await request("newposts?" + param.toString(), method);
        const res = await request("newposts", method, data);
        if (res && res.success) {
          openNotification(
            "success",
            "Success",
            id === null ? " Save is Sucess" : "Update Post is Sucess"
          );
          setModelVisable(false);
          setTitle("");
          setCategory("");
          setCoverImageUrl("");
          setContent("");

          GetList(objFilter);
          setloading(false);
        }
      } catch (error) {
        openNotification("error", "Error", error.message);
      } finally {
        setloading(false);
      }
    }
  };
  const GetList = async (objFilter) => {
    const param = new URLSearchParams({
      page: objFilter.page,
      txtsearch: objFilter.txtsearch,
      txtcategory: objFilter.txtcategory,
    });
    setloading(true);
    try {
      // await new Promise((resolve) => setTimeout(resolve, 500));
      const res = await request("postsall?" + param.toString(), "get", {});
      if (res) {
        setDataSource(res.posts_API);
        setTotalPage(res.pagecount[0].total);
        console.log("this  is list from server:", res);
        console.log("page size:", res.pagecount[0]);
        if (objFilter.page === 1) {
          setTotalPage(res.pagecount[0].total);
        }
        setloading(false);
      } else {
        console.log("somthing is wrong ");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  const GetListEdit = async () => {
    try {
      const res = await request("postsapi", "get", {});
      if (res && res.category_API) {
        setListCategory(res.category_API);
      } else {
        console.log("Error fetching categories");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const no_cover = "https://parliament.gov.gy/assets/css/Images/no_cover.png";
  const columns = [
    {
      title: "Posts Cover",
      dataIndex: "post_cover",
      key: "post_cover",
      render: (post_cover) => {
        return (
          <div
            style={{ width: 200, height: 120, border: 1, borderColor: "blue" }}
          >
            <img
              style={{ width: 200, height: 120 }}
              src={post_cover || no_cover}
              alt="Cover Image"
              onError={(e) => {
                e.target.onerror = null; // Prevents infinite loop if fallback image fails
                e.target.src = no_cover;
              }}
            />
          </div>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Posts Title",
      dataIndex: "post_title",
      key: "post_title",
    },
    {
      title: "Category",
      dataIndex: "post_category",
      key: "post_category",
    },

    {
      title: "Create Date",
      dataIndex: "post_date",
      key: "create_date",
    },
    {
      title: "Create By",
      dataIndex: "post_by",
      key: "create_by",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <div>
            <Tag
              color={status == 1 ? "#108ee9" : "#f50"}
              style={{
                width: 100,
                height: 30,
                fontWeight: "bold",

                alignContent: "center",
              }}
            >
              {status == 1 ? "Active" : "Not Active"}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (item, record) => {
        return (
          <div>
            <Space>
              <Button type="primary" onClick={() => onOpenEdit(record)}>
                Edit
              </Button>
              <div>
                <Popconfirm
                  placement="topRight"
                  title={"Delete Record"}
                  description={"do you want to delete this recorde?"}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => onDeletePost(record)}
                >
                  <Button danger type="primary">
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            </Space>
          </div>
        );
      },
    },
  ];
  const onCahngeCategory = () => {};
  const onOpenEdit = (record) => {
    setID(record.id);
    setTitle(record.post_title);
    setCoverImageUrl(record.post_cover);
    setContent(record.post_content);
    setCategory(record.idcategory);
    record.status === 1 ? setEnable(true) : setDisable(true);
    setModelVisable(true);
  };
  const onDeletePost = async (record) => {
    console.log("onDeletePost", record.id);
    const param = new URLSearchParams({
      id: record.id,
    });
    const res = await request("postdelete?" + param.toString(), "get", {});
    if (res) {
      openNotification("success", "Success", "Delete post successfully");
      GetList(objFilter);
    } else {
      openNotification("error", "Error", "Somthing is wrong");
    }
  };
  const onChangeContents = (e) => {
    setContent(e);
  };

  const onChangeCategory = (value) => {
    setCategory(value);
  };
  const handleResetContent = () => {
    setID(null);
    setTitle("");
    setCoverImageUrl("");
    setContent("");
    setCategory("");
  };
  const onCreateNew = () => {
    setID(null);
    setEnable(true);
    setDisable(false);
    setModelVisable(true);
    handleResetContent();
  };
  const handleCheckboxChange_disable = () => {
    setDisable(true);
    setEnable(false);
  };
  const handleCheckboxChange_enable = () => {
    setEnable(true);
    setDisable(false);
  };
  const [coverPreview, setCoverPreview] = useState("");
  const onChangeCoverImage = (e) => {
    setCoverImageUrl(e.target.value);
    setCoverPreview(coverImageUrl);
  };
  const onChangeSearch = (e) => {
    setSearchTerm(e.target.value);
    console.log("onChangeSearch", searchTerm);
  };
  const onChangePagenumber = (page) => {
    var objFilterTem = {
      ...objFilter,
      page: page,
    };
    GetList(objFilterTem);
  };
  return (
    <div>
      <div className="sprinSave-container">
        {loading && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              visible={true}
            />
          </div>
        )}
      </div>

      <div>
        <Button type="primary" onClick={onCreateNew}>
          Create New
        </Button>
      </div>
      <div>
        <h3>Manage Posting on Website</h3>
      </div>
      <div style={{ paddingBottom: 10 }}>
        <Space>
          <Input
            onChange={onChangeSearch}
            placeholder="Search Post..."
            style={{ width: 200, height: 40 }}
            type="text"
          />
          <Select
            style={{ width: 250, height: 35, display: "none" }}
            placeholder="Select a Category"
            onChange={onChangeCategory}
            value={category}
          >
            {ListCategory.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.Name}{" "}
                {/* Replace with the correct field from your MySQL table */}
              </Option>
            ))}
          </Select>
        </Space>
      </div>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
            margin: 20,
          }}
        >
          <Pagination
            onChange={onChangePagenumber}
            className="custom-pagination"
            size="large"
            defaultCurrent={1}
            pageSize={10}
            total={TotalPage}
          />
        </div>
        <div style={{ margin: 10 }}>
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </div>
      </div>

      <Modal
        width={1000}
        open={visableModel}
        onCancel={() => {
          setModelVisable(false);
          setEnable(false);
          setDisable(false);
        }}
        title={id === null ? "Create New" : "Update Posts"}
        footer={
          <div>
            <Space style={{ paddingTop: 20 }}>
              <Button type="primary" onClick={handleCreatePost}>
                {id === null ? " Create Post" : "Update Post"}
              </Button>
              <Button type="primary" danger onClick={handleResetContent}>
                Reset Content
              </Button>
            </Space>
          </div>
        }
      >
        <div style={{ paddingLeft: 40, paddingRight: 40 }}>
          <h4>What going in your posts today?</h4>
          <div>
            <p style={{ color: "blue", fontWeight: "bold" }}>Post title *</p>
            <TextArea
              rows={4}
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div>
            <p style={{ color: "blue", fontWeight: "bold" }}>
              Post Cover URL *
            </p>
            <Input
              style={{ height: 40 }}
              type="text"
              value={coverImageUrl}
              onChange={onChangeCoverImage}
            />
            <div style={{ width: 200, height: 100, padding: 10 }}>
              <img
                style={{ width: 200, height: 100 }}
                src={coverImageUrl}
                alt="cover-pre"
              />
            </div>
          </div>
          <div>
            <p style={{ color: "blue", fontWeight: "bold" }}>Post Content *</p>
            <JoditEditor value={content} onChange={onChangeContents} />
          </div>
          <div style={{ paddingTop: 10 }}>
            <p style={{ color: "blue", fontWeight: "bold" }}>Category *</p>
            <Select
              style={{ width: 250, height: 35 }}
              placeholder="Select a Category"
              onChange={onChangeCategory}
              value={category}
            >
              {ListCategory.map((option) => (
                <Option key={option.id} value={option.id}>
                  {option.Name}{" "}
                  {/* Replace with the correct field from your MySQL table */}
                </Option>
              ))}
            </Select>
            <Tag color="magenta" style={{ marginLeft: 20 }}>
              <div style={{ padding: 10 }}>
                <Checkbox
                  checked={enable}
                  onChange={handleCheckboxChange_enable}
                >
                  Enable
                </Checkbox>
                <Checkbox
                  style={{ color: "red" }}
                  checked={disable}
                  onChange={handleCheckboxChange_disable}
                >
                  Disable
                </Checkbox>
              </div>
            </Tag>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Home;
